
import { UpdatePassword } from "@/services/auth.service";
import { Options, setup, Vue } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    data() {
        return {
            passwordResetFormData: {
                email: (this.$route.query.email?.toString() || " ").replace(
                    " ",
                    "+"
                ),
                token: this.$route.query.token,
                password: "",
                password_confirmation: "",
            },
        };
    },
    components: {},
    methods: {
        onSubmit() {
            UpdatePassword(this.passwordResetFormData)
                .then((resp: any) => {
                    this.toast.success(
                        resp.data.message || "Password updated successfully."
                    );
                    this.$router.push("/login");
                })
                .catch((error: any) => {
                    this.toast.error(error.message);
                });
        },
    },
})
export default class LogIn extends Vue {
    toast = setup(() => useToast());
}
